import request from '@/utils/request'
import { Member } from '@/interface/api'

const prefix = '/api/v3/customer/clinics/doctor-list'

export const retrieveMembers = (medicalBusinessId: string, clinicId: string, clinicalDepartmentId: string, isFirstTreatment:number, treatmentCategoryName: string): Promise<Member[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    method: 'get',
    params: { is_first_treatment: isFirstTreatment, treatment_category_name: treatmentCategoryName}
  }).then(response => response.data.data.attributes)

export const retrieveTreatmentCourseMembers = (medicalBusinessId: string, clinicId: string, clinicalDepartmentId: string, treatmentCourseId: string, isFirstTreatment:number, treatmentCategoryName: string): Promise<Member[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/${treatmentCourseId}/`,
    method: 'get',
    params: { is_first_treatment: isFirstTreatment, treatment_category_name: treatmentCategoryName}
  }).then(response => response.data.data.attributes)
