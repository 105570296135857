import request from '@/utils/request'
import { MedicalTreatmentSpot, SearchParams } from '@/interface/api'

const prefix = '/api/v2/customer/clinics/treatment-spots'
const prefixCourse = '/api/v3/customer/clinics/treatment-spots'

export const retrieveAllTreatmentSpots = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  params: SearchParams
): Promise<MedicalTreatmentSpot[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    params: params,
    method: 'get'
  }).then(response => response.data.data.attributes.schedules)

export const retrieveTreatmentSpots = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  memberId: string,
  params: SearchParams
): Promise<MedicalTreatmentSpot[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/${memberId}/`,
    params: params,
    method: 'get'
  }).then(response => response.data.data.attributes.schedules)

export const retrieveAllTreatmentCourseSpots = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  treatmentCourseId: string,
  params: SearchParams
): Promise<MedicalTreatmentSpot[]> =>
  request({
    url: `${prefixCourse}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/${treatmentCourseId}/`,
    params: params,
    method: 'get'
  }).then(response => response.data.data.attributes.schedules)

export const retrieveTreatmentCourseSpots = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  treatmentCourseId: string,
  memberId: string,
  params: SearchParams
): Promise<MedicalTreatmentSpot[]> =>
  request({
    url: `${prefixCourse}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/${treatmentCourseId}/${memberId}/`,
    params: params,
    method: 'get'
  }).then(response => response.data.data.attributes.schedules)
