import { ClinicDepartmentTreatmentCourse } from '@/interface/api'

export const filterTreatmentCourseCategory = (
  course: ClinicDepartmentTreatmentCourse,
  selected: 'face_to_face' | 'video'
) => {
  const executeFilter =
    selected === 'face_to_face'
      ? filterTreatmentCourseCategoryOffline
      : filterTreatmentCourseCategoryOnline
  return executeFilter(course)
}

export const filterTreatmentFirstTreatment = (
  course: ClinicDepartmentTreatmentCourse,
  selected: 1 | 0
) => {
  const executeFilter =
    selected === 1
      ? filterTreatmentCourseIsFirst
      : filterTreatmentCourseIsSecond
  return executeFilter(course)
}

type ClinicDepartmentTreatmentCourseFilterKeys = keyof Pick<
  ClinicDepartmentTreatmentCourse,
  | 'is_online_treatment'
  | 'is_offline_treatment'
  | 'is_first_treatment'
  | 'is_second_treatment'
>

const filterTreatmentCourse = (
  course: ClinicDepartmentTreatmentCourse,
  key: ClinicDepartmentTreatmentCourseFilterKeys
) => course[key] === 1

const filterTreatmentCourseCategoryOnline = (
  course: ClinicDepartmentTreatmentCourse
) => filterTreatmentCourse(course, 'is_online_treatment')

const filterTreatmentCourseCategoryOffline = (
  course: ClinicDepartmentTreatmentCourse
) => filterTreatmentCourse(course, 'is_offline_treatment')

const filterTreatmentCourseIsFirst = (
  course: ClinicDepartmentTreatmentCourse
) => filterTreatmentCourse(course, 'is_first_treatment')

const filterTreatmentCourseIsSecond = (
  course: ClinicDepartmentTreatmentCourse
) => filterTreatmentCourse(course, 'is_second_treatment')
