import request from '@/utils/request'
import {
  MedicalBusinessRegisterRequest,
  MedicalBusinessRegisterResponse
} from '@/interface/api'
import { AlertModule } from '@/store/modules/alert'

const prefix = 'api/v3/customer/web-first-reservation'

export const createTreatment = (
  medicalBusinessId: string,
  temporaryReservationId: string,
  medicalTreatment: MedicalBusinessRegisterRequest
): Promise<MedicalBusinessRegisterResponse> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${temporaryReservationId}/`,
    method: 'post',
    data: medicalTreatment
  })
    .then(() => {
      const response: MedicalBusinessRegisterResponse = {
        result: true,
        message: ''
      }
      return response
    })
    .catch(e => {
      if (
        e.response.data.data.attributes.message ===
        'already_same_phone_number_is_registered'
      ) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message: '診察登録に失敗しました。'
        })
        const response: MedicalBusinessRegisterResponse = {
          result: false,
          message: 'phone_number_error'
        }
        return response
      } else {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message: '診察登録に失敗しました。'
        })
        const response: MedicalBusinessRegisterResponse = {
          result: false,
          message: 'error'
        }
        return response
      }
    })
