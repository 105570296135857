







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import dayjs from '@/plugins/dayjs'
import { ClinicResponse, Spot, MedicalTreatmentSpot } from '@/interface/api'
import { formatDate } from '@/utils/index'
import { DateFormat } from '@/utils/constants'
@Component({})
export default class TreatmentSpotSelectTemplate extends Vue {
  @Prop({ default: () => null }) private clinic!: ClinicResponse
  @Prop({ default: () => [] }) private publicHolidyList!: string[]
  @Prop({ default: () => [] }) private medicalTreatmentSpots!: MedicalTreatmentSpot[]

  private get targetDates() :{label: string, date: string}[] {
    const dates = this.medicalTreatmentSpots.map(treatmentSpot => {
      return {
        label: formatDate(new Date(treatmentSpot.day), DateFormat.D) + '\n' + formatDate(new Date(treatmentSpot.day), DateFormat.dd),
        date: treatmentSpot.day
      }
    })
    return dates
  }

  private get treatmentSpotTimes() :string[] {
    const times: string[] = []
    const startTimes = this.clinic.business_hours.map(businessHours => {
      const time = businessHours.open_time.split(':')
      const date = new Date(1970, 0, 1, Number(time[0]), Number(time[1]))
      return dayjs(date)
    })
    const minStartTime = dayjs.min(startTimes)
    const endTimes = this.clinic.business_hours.map(businessHours => {
      const time = businessHours.close_time.split(':')
      const date = new Date(1970, 0, 1, Number(time[0]), Number(time[1]))
      return dayjs(date)
    })
    const maxEndTime = dayjs.max(endTimes)
    const intervalCount = maxEndTime.diff(minStartTime, 'minute') / 30
    times.push(minStartTime.format(DateFormat.HH_mm))
    for (let i = 1; i < intervalCount; i++) times.push(minStartTime.clone().add(i * 30, 'm').format(DateFormat.HH_mm))
    times.push(maxEndTime.format(DateFormat.HH_mm))
    return times
  }

  private isAvailableSpot(date: string, spotTime: string) :boolean {
    if (this.clinic.clinic.holiday_flag && this.publicHolidyList.includes(formatDate(new Date(date), DateFormat.YYYY_MM_DD_HYPHEN))) return false
    const targetMedicalTreatmentSpot = this.medicalTreatmentSpots.find(medicalTreatmentSpot => medicalTreatmentSpot.day === date)
    const targetSpot = targetMedicalTreatmentSpot!.spots.find(spot => 
      formatDate(new Date(spot.spot_datetime), DateFormat.HH_mm) === spotTime)
    if (!targetSpot) return false
    return true
  }

  private selectTreatmentSpot(date: string, spotTime: string) {
    const targetMedicalTreatmentSpot = this.medicalTreatmentSpots.find(medicalTreatmentSpot => medicalTreatmentSpot.day === date)
    const targetSpot = targetMedicalTreatmentSpot!.spots.find(spot =>
      formatDate(new Date(spot.spot_datetime), DateFormat.HH_mm) === spotTime)
    this.$emit('on-treatment-spot-select', targetSpot!.id, targetSpot!.spot_datetime)
  }
}
