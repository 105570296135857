



































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SelectClinicTemplate from '@/views/treatment-reservation/templates/SelectClinicTemplate.vue'
import TreatmentDetailTemplate from '@/views/treatment-reservation/templates/TreatmentDetailTemplate'
import ReservationCautionTemplate from '@/views/treatment-reservation/templates/ReservationCautionTemplate.vue'
import ConsentTemplate from '@/views/treatment-reservation/templates/ConsentTemplate.vue'
import SelectDoctorTemplate from '@/views/treatment-reservation/templates/SelectDoctorTemplate.vue'
import SelectDateTimeTemplate from '@/views/treatment-reservation/templates/SelectDateTimeTemplate.vue'
import CustomerFreeDiscribeTemplate from '@/views/treatment-reservation/templates/CustomerFreeDiscribeTemplate.vue'
import PersonalInfomationTemplate from '@/views/treatment-reservation/templates/PersonalInfomationTemplate.vue'
import ConfirmTemplate from '@/views/treatment-reservation/templates/ConfirmTemplate.vue'
import {
  ClinicResponse,
  ReservationCaution,
  Consent,
  Member,
  MedicalTreatmentSpot,
  CustomerFreeDescribe,
  PersonalInfomation,
  MedicalBusinessRegisterRequest
} from '@/interface/api'
import { retrieveClinics } from '@/api/clinic'
import { retrieveTraetmentNotices } from '@/api/traetment-notice'
import { retrieveTraetmentConsents } from '@/api/traetment-consent'
import { retrieveMembers, retrieveTreatmentCourseMembers } from '@/api/member'
import {
  beforeCheckMedicalTreatmentSpot,
  beforeCheckMedicalDoctorTreatmentSpot
} from '@/api/treatment-before-check'
import {
  retrieveTreatmentSpots,
  retrieveAllTreatmentSpots,
  retrieveAllTreatmentCourseSpots,
  retrieveTreatmentCourseSpots
} from '@/api/treatment-spot'
import { retrieveCustomerDescription } from '@/api/traetment-customer-description'
import {
  checkMedicalTreatmentSpot,
  checkMedicalDoctorTreatmentSpot
} from '@/api/treatment-check'
import { createTreatment } from '@/api/treatment'
import { getPublicHoliday } from '@/api/getPublicDay'
import { formatDate, formatUuid } from '@/utils/index'
import {
  DateFormat,
  NO_DESIRED_DOCTOR,
  TreatmentCategory
} from '@/utils/constants'
import { RouteName } from '@/router'
import { AlertModule } from '@/store/modules/alert'

@Component({
  name: 'TreatmentReservationPage',
  components: {
    SelectClinicTemplate,
    TreatmentDetailTemplate,
    ReservationCautionTemplate,
    ConsentTemplate,
    SelectDoctorTemplate,
    SelectDateTimeTemplate,
    CustomerFreeDiscribeTemplate,
    PersonalInfomationTemplate,
    ConfirmTemplate
  }
})
export default class TreatmentReservationPage extends Vue {
  @Prop({ default: '' }) private medicalBusinessId!: string
  @Prop({ default: false }) private canFirstReservationForEachDoctor!: boolean
  @Prop({ default: false })
  private canSecondLaterReservationForEachDoctor!: boolean

  @Prop({ default: false }) private canFaceToFace!: boolean
  @Prop({ default: false }) private canOnlineTreatment!: boolean
  private clinics: ClinicResponse[] = []
  private selectedClinic: ClinicResponse | null = null
  private traetmentNotices: ReservationCaution[] = []
  private consents: Consent[] = []
  private customerFreeDescribe: CustomerFreeDescribe[] = []
  private publicHolidyList: string[] = []
  private selectedClinicalDepartmentId = ''
  private isFirstTreatment = 1
  private doctors: Member[] = []
  private selectedDoctorId = ''
  private selectedDoctorName = ''
  private selectedTreatmentCategoryName = ''
  private selectedClinicDeaprtmentTreatmentCourseId = ''
  private agreedConsentIds: string[] = []
  private startsAt = new Date()
  private endsAt = new Date()
  private temporaryReservationId = ''
  private customerDescription = ''
  private medicalTreatmentSpots: MedicalTreatmentSpot[] = []
  private spotId = ''
  private spotStartDatetime = new Date()
  private spotEndDatetime = new Date()
  private inputedPersonalInfo: PersonalInfomation = {
    phone_number: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    sex: 1,
    birthdate: ''
  }

  private request = {}
  private loading = false
  private step = 0

  private get canFirstReservationForEachDoctorFlug() {
    return this.isFirstTreatment === 1 && this.canFirstReservationForEachDoctor
  }

  private get canSecondLaterReservationForEachDoctorFlug() {
    return (
      this.isFirstTreatment === 0 && this.canSecondLaterReservationForEachDoctor
    )
  }

  @Watch('medicalBusinessId', { immediate: true })
  private async onMedicalBusinessIdChange() {
    if (!this.medicalBusinessId) return
    this.clinics = await retrieveClinics(this.medicalBusinessId)
  }

  // クリニック選択
  private onClinicSelect(clinic: ClinicResponse) {
    this.selectedClinic = clinic
    this.selectedClinicalDepartmentId =
      this.selectedClinic.clinical_departments[0].id
    this.isFirstTreatment = 1
    const option = []
    if (this.canFaceToFace) {
      this.selectedTreatmentCategoryName = TreatmentCategory.FcaeToFace
    } else if (this.canOnlineTreatment) {
      this.selectedTreatmentCategoryName = TreatmentCategory.Video
    }
    this.step = 1
  }

  // 診察詳細入力
  private onBackFromTreatmentDetail() {
    this.selectedClinicalDepartmentId = ''
    this.isFirstTreatment = 1
    this.selectedTreatmentCategoryName = ''
    this.step = 0
  }

  private async onTreatmentDetailInput(
    selectedClinicalDepartmentId: string,
    isFirstTreatment: number,
    selectedTreatmentCategoryName: string,
    selectedClinicDeaprtmentTreatmentCourseId: string
  ) {
    this.loading = true
    this.selectedClinicalDepartmentId = selectedClinicalDepartmentId
    this.isFirstTreatment = isFirstTreatment
    this.selectedTreatmentCategoryName = selectedTreatmentCategoryName
    this.selectedClinicDeaprtmentTreatmentCourseId =
      selectedClinicDeaprtmentTreatmentCourseId
    const params = {
      treatment_category_name: this.selectedTreatmentCategoryName,
      is_first_treatment: this.isFirstTreatment,
      is_before_treatment: 1
    }
    this.traetmentNotices = await retrieveTraetmentNotices(
      this.medicalBusinessId,
      formatUuid(this.selectedClinic!.clinic.id),
      formatUuid(this.selectedClinicalDepartmentId),
      params
    )
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      this.loading = false
    } else {
      this.consents = await retrieveTraetmentConsents(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(this.selectedClinicalDepartmentId),
        params
      )
      if (this.consents.length !== 0) {
        this.step = 3
        this.loading = false
      } else {
        console.log(this.canFirstReservationForEachDoctorFlug)
        console.log(this.canSecondLaterReservationForEachDoctorFlug)
        if (
          (this.canFirstReservationForEachDoctorFlug &&  this.isFirstTreatment === 1) ||
          (this.canSecondLaterReservationForEachDoctorFlug && this.isFirstTreatment === 0)
        ) {
          if (this.selectedClinicDeaprtmentTreatmentCourseId) {
            this.doctors = await retrieveTreatmentCourseMembers(
              this.medicalBusinessId,
              formatUuid(this.selectedClinic!.clinic.id),
              formatUuid(this.selectedClinicalDepartmentId),
              formatUuid(this.selectedClinicDeaprtmentTreatmentCourseId),
              this.isFirstTreatment,
              this.selectedTreatmentCategoryName
            )
          } else {
            this.doctors = await retrieveMembers(
              this.medicalBusinessId,
              formatUuid(this.selectedClinic!.clinic.id),
              formatUuid(this.selectedClinicalDepartmentId),
              this.isFirstTreatment,
              this.selectedTreatmentCategoryName
            )
          }
          if (this.doctors.length !== 0) {
            this.step = 4
            this.loading = false
            return
          }
        }
        await this.fetchTreatmentSpot(new Date())
        this.publicHolidyList = await getPublicHoliday()
        this.step = 5
        this.loading = false
      }
    }
  }

  // 診察注意
  private onBackFromTreatmentNotice() {
    this.traetmentNotices = []
    this.step = 1
  }

  private async onTreatmentNoticeCheck() {
    this.loading = true
    const params = {
      treatment_category_name: this.selectedTreatmentCategoryName,
      is_first_treatment: this.isFirstTreatment,
      is_before_treatment: 1
    }
    this.consents = await retrieveTraetmentConsents(
      this.medicalBusinessId,
      formatUuid(this.selectedClinic!.clinic.id),
      formatUuid(this.selectedClinicalDepartmentId),
      params
    )
    if (this.consents.length !== 0) {
      this.step = 3
      this.loading = false
    } else {
      if (
        this.canFirstReservationForEachDoctorFlug ||
        this.canSecondLaterReservationForEachDoctorFlug
      ) {
        this.doctors = await retrieveMembers(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          this.isFirstTreatment,
          this.selectedTreatmentCategoryName
        )
        if (this.doctors.length !== 0) {
          this.step = 4
          this.loading = false
          return
        }
      }
      await this.fetchTreatmentSpot(new Date())
      this.publicHolidyList = await getPublicHoliday()
      this.step = 5
      this.loading = false
    }
  }

  // 同意書
  private onBackFromConsent() {
    this.consents = []
    this.agreedConsentIds = []
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      return
    }
    this.step = 1
  }

  private async onTreatmentConsentAgree() {
    this.loading = true
    this.agreedConsentIds = this.consents.map(consent => consent.id)
    if (
      this.canFirstReservationForEachDoctorFlug ||
      this.canSecondLaterReservationForEachDoctorFlug
    ) {
      this.doctors = await retrieveMembers(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(this.selectedClinicalDepartmentId),
        this.isFirstTreatment,
        this.selectedTreatmentCategoryName
      )
      if (this.doctors.length !== 0) {
        this.step = 4
        this.loading = false
        return
      }
    }
    await this.fetchTreatmentSpot(new Date())
    this.publicHolidyList = await getPublicHoliday()
    this.step = 5
    this.loading = false
  }

  // 医師選択
  private async onBackFromSelectDoctor() {
    this.selectedDoctorId = ''
    this.selectedDoctorName = ''
    if (this.consents.length !== 0) {
      this.step = 3
      return
    }
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      return
    }
    this.step = 1
  }

  private async onSelectDoctor(doctorId: string, doctorName: string) {
    this.loading = true
    this.selectedDoctorId = doctorId
    this.selectedDoctorName = doctorName
    await this.fetchTreatmentSpot(new Date())
    this.publicHolidyList = await getPublicHoliday()
    this.step = 5
    this.loading = false
  }

  // 日時選択
  private onBackFromSelectDateTime() {
    this.medicalTreatmentSpots = []
    this.spotId = ''
    this.spotStartDatetime = new Date()
    this.spotEndDatetime = new Date()
    if (
      (this.canFirstReservationForEachDoctorFlug ||
        this.canFirstReservationForEachDoctorFlug) &&
      this.doctors.length !== 0
    ) {
      this.step = 4
      return
    }
    if (this.consents.length !== 0) {
      this.step = 3
      return
    }
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      return
    }
    this.step = 1
  }

  private async setTreatmentSpot(spotId: string, spotDatetime: Date) {
    this.loading = true
    const checkresult = await this.beforeCheck(spotId, spotDatetime)
    if (checkresult) {
      const params = {
        treatment_category_name: this.selectedTreatmentCategoryName,
        is_first_treatment: this.isFirstTreatment,
        is_before_treatment: 1
      }
      this.customerFreeDescribe = await retrieveCustomerDescription(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(this.selectedClinicalDepartmentId),
        params
      )
      if (this.customerFreeDescribe.length !== 0) {
        this.step = 6
        this.loading = false
        return
      }
      this.step = 7
      this.loading = false
    } else {
      await this.fetchTreatmentSpot(new Date())
      this.publicHolidyList = await getPublicHoliday()
      this.loading = false
    }
  }

  // 患者記述
  private async onBackFromCustomerFreeDescription() {
    this.customerFreeDescribe = []
    this.customerDescription = ''
    this.step = 5
    await this.fetchTreatmentSpot(new Date())
    this.publicHolidyList = await getPublicHoliday()
  }

  private async onCustomerFreeDescriptionInput(customerDescription: string) {
    this.loading = true
    this.customerDescription = customerDescription
    this.step = 7
    this.loading = false
  }

  // 個人情報入力
  private async onBackFromPersonalInfomation() {
    if (this.customerFreeDescribe.length !== 0) {
      this.step = 6
      return
    }
    this.step = 5
    await this.fetchTreatmentSpot(new Date())
    this.publicHolidyList = await getPublicHoliday()
  }

  private onInputPersonalInfo(personalInfomation: PersonalInfomation) {
    this.loading = true
    this.inputedPersonalInfo = personalInfomation
    this.step = 8
    this.loading = false
  }

  private async editInfo(step: number) {
    if (step === 0) {
      this.selectedClinicalDepartmentId = ''
      this.isFirstTreatment = 1
      this.selectedTreatmentCategoryName = ''
      this.selectedClinicDeaprtmentTreatmentCourseId = ''
      this.traetmentNotices = []
      this.consents = []
      this.agreedConsentIds = []
      this.medicalTreatmentSpots = []
      this.spotId = ''
      this.spotStartDatetime = new Date()
      this.spotEndDatetime = new Date()
      this.customerFreeDescribe = []
      this.customerDescription = ''
    }
    if (step === 1) {
      this.consents = []
      this.agreedConsentIds = []
      this.medicalTreatmentSpots = []
      this.spotId = ''
      this.spotStartDatetime = new Date()
      this.spotEndDatetime = new Date()
      this.customerFreeDescribe = []
      this.customerDescription = ''
    }
    if (step === 4) {
      this.selectedDoctorId = ''
      this.selectedDoctorName = ''
    }
    if (step === 5) {
      this.spotId = ''
      this.spotStartDatetime = new Date()
      this.spotEndDatetime = new Date()
      this.customerFreeDescribe = []
      this.customerDescription = ''
      await this.fetchTreatmentSpot(new Date())
      this.publicHolidyList = await getPublicHoliday()
    }
    this.step = step
  }

  private async send() {
    this.loading = true
    let checkResult
    if (
      this.selectedDoctorId &&
      this.selectedDoctorId !== NO_DESIRED_DOCTOR.value
    ) {
      checkResult = await checkMedicalDoctorTreatmentSpot(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(this.spotId),
        formatUuid(this.selectedDoctorId)
      )
    } else {
      checkResult = await checkMedicalTreatmentSpot(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(this.spotId)
      )
    }
    if (checkResult.result) {
      this.temporaryReservationId = checkResult.temporary_reservation_id
      this.startsAt = checkResult.treatment.starts_at
      this.endsAt = checkResult.treatment.ends_at
      const request: MedicalBusinessRegisterRequest = {
        customer: {
          phone_number: this.inputedPersonalInfo.phone_number,
          last_name: this.inputedPersonalInfo.last_name,
          first_name: this.inputedPersonalInfo.first_name,
          last_name_kana: this.inputedPersonalInfo.last_name_kana,
          first_name_kana: this.inputedPersonalInfo.first_name_kana,
          birthday: this.inputedPersonalInfo.birthdate,
          sex: this.inputedPersonalInfo.sex
        },
        treatment: {
          clinic_id: this.selectedClinic!.clinic.id,
          clinica_department_id: this.selectedClinicalDepartmentId,
          is_first_treatment: this.isFirstTreatment,
          treatment_category_name: this.selectedTreatmentCategoryName,
          prior_consents: this.agreedConsentIds,
          customer_description: this.customerDescription,
          customer_describe_id:
            this.customerFreeDescribe.length !== 0
              ? this.customerFreeDescribe[0].id
              : '',
          starts_at: this.startsAt,
          ends_at: this.endsAt,
          temporary_reservation_id: this.temporaryReservationId,
          treatment_course_id: this.selectedClinicDeaprtmentTreatmentCourseId
        }
      }
      const res = await createTreatment(
        this.medicalBusinessId,
        formatUuid(this.temporaryReservationId),
        request
      )
      if (!res.result) {
        // 診察登録失敗の場合はエラー画面に遷移
        this.loading = false
        if (res.message === 'phone_number_error') {
          this.$router.push({
            name: RouteName.ERROR,
            params: { error: res.message }
          })
          return
        }
        this.$router.push({ name: RouteName.ERROR })
        return
      }
      // 診察登録成功の場合は完了画面に遷移
      if (
        this.startsAt !== this.spotStartDatetime ||
        this.endsAt !== this.spotEndDatetime
      ) {
        AlertModule.SetWarningAlert({
          warningTime: new Date(),
          message: `指定した診察枠が確保できなかったため、\n${
            formatDate(this.startsAt, DateFormat.YYYY_MM_DD_HH_mm) +
            ' 〜 ' +
            formatDate(this.endsAt, DateFormat.YYYY_MM_DD_HH_mm)
          }で診察予約をお取りしています。`
        })
      }
      this.loading = false
      this.$router.push({
        name: RouteName.TREATMENT_RESERVATION_COMPLETE,
        params: { medicalBusinessId: this.medicalBusinessId }
      })
      return
    }
    // 枠が取れなかった場合は枠選択に遷移
    this.loading = false
    await this.editInfo(4)
  }

  private async beforeCheck(
    spotId: string,
    spotDatetime: Date
  ): Promise<boolean> {
    let res
    if (
      this.selectedDoctorId &&
      this.selectedDoctorId !== NO_DESIRED_DOCTOR.value
    ) {
      res = await beforeCheckMedicalDoctorTreatmentSpot(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(spotId),
        formatUuid(this.selectedDoctorId)
      )
    } else {
      res = await beforeCheckMedicalTreatmentSpot(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(spotId)
      )
    }
    if (res.result) {
      this.spotId = spotId
      this.spotStartDatetime = res.treatment.starts_at
      this.spotEndDatetime = res.treatment.ends_at
      return true
    }
    return false
  }

  private async fetchTreatmentSpot(startDate: Date) {
    const params = {
      reservation_spot_date: formatDate(
        startDate,
        DateFormat.YYYY_MM_DD_HYPHEN
      ),
      treatment_category_name: this.selectedTreatmentCategoryName,
      is_first_treatment: this.isFirstTreatment
    }
    if (
      this.selectedDoctorId &&
      this.selectedDoctorId !== NO_DESIRED_DOCTOR.value
    ) {
      if (this.selectedClinicDeaprtmentTreatmentCourseId) {
        this.medicalTreatmentSpots = await retrieveTreatmentCourseSpots(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          formatUuid(this.selectedClinicDeaprtmentTreatmentCourseId),
          formatUuid(this.selectedDoctorId),
          params
        )
      } else {
        this.medicalTreatmentSpots = await retrieveTreatmentSpots(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          formatUuid(this.selectedDoctorId),
          params
        )
      }
    } else {
      if (this.selectedClinicDeaprtmentTreatmentCourseId) {
        this.medicalTreatmentSpots = await retrieveAllTreatmentCourseSpots(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          formatUuid(this.selectedClinicDeaprtmentTreatmentCourseId),
          params
        )
      } else {
        this.medicalTreatmentSpots = await retrieveAllTreatmentSpots(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          params
        )
      }
    }
  }
}
