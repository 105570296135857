import { render, staticRenderFns } from "./TreatmentReservationPage.vue?vue&type=template&id=e8299094&scoped=true&"
import script from "./TreatmentReservationPage.vue?vue&type=script&lang=ts&"
export * from "./TreatmentReservationPage.vue?vue&type=script&lang=ts&"
import style0 from "./TreatmentReservationPage.vue?vue&type=style&index=0&id=e8299094&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8299094",
  null
  
)

export default component.exports