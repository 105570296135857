import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'

dayjs.locale('ja')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(minMax)

export default dayjs
