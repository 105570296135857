import request from '@/utils/request'
import { ClinicResponse } from '@/interface/api'

const prefix = '/api/v2/customer/clinics'

export const retrieveClinics = (
  medicalBusinessId: string
): Promise<ClinicResponse[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/`,
    method: 'get',
    params: { part: 'name,address,business_hours,holidays' }
  }).then(response => response.data.data.attributes)
