




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Consent } from '@/interface/api'

@Component({
  name: 'ConsentTemplate'
})
export default class ConsentTemplate extends Vue {
  @Prop({ default: () => [] }) private consents!: Consent[]
  @Prop({ default: false }) private loading!: boolean
}
