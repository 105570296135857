








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CustomerFreeDescribe } from '@/interface/api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

@Component({
  name: 'CustomerFreeDiscribeTemplate',
  components: { ValidationProvider, ValidationObserver }
})
export default class CustomerFreeDiscribeTemplate extends Vue {
  @Prop({ default: () => [] }) private customerFreeDescribe!: CustomerFreeDescribe[]
  @Prop({ default: false }) private loading!: boolean
  private customerDescription = ''
}
