import request from '@/utils/request'
import { MedicalTreatmentSpotCheck } from '@/interface/api'
import { AlertModule } from '@/store/modules/alert'

const prefix = '/api/v3/customer/clinics/treatment-spots/check'
const treatmentMemberSpotPrefix =
  '/api/v3/customer/clinics/treatment-member-spots/check'

export const checkMedicalTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  treatmentSpotId: string
): Promise<MedicalTreatmentSpotCheck> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${treatmentSpotId}/`,
    method: 'post'
  })
    .then(response => {
      if (!response.data.data.attributes.result) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return response.data.data.attributes
    })
    .catch(() => {
      AlertModule.SetErrorAlert({
        errorTime: new Date(),
        message:
          '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
      })
      return false
    })

export const checkMedicalDoctorTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  treatmentSpotId: string,
  memberId: string
): Promise<MedicalTreatmentSpotCheck> =>
  request({
    url: `${treatmentMemberSpotPrefix}/${medicalBusinessId}/${clinicId}/${treatmentSpotId}/${memberId}/`,
    method: 'post'
  })
    .then(response => {
      if (!response.data.data.attributes.result) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return response.data.data.attributes
    })
    .catch(() => {
      AlertModule.SetErrorAlert({
        errorTime: new Date(),
        message:
          '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
      })
      return false
    })
