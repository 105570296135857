import request from '@/utils/request'
import { MedicalTreatmentSpotBeforeCheckResponse } from '@/interface/api'
import { AlertModule } from '@/store/modules/alert'

const prefix = '/api/v3/customer/clinics/treatment-spots/before-check'

export const beforeCheckMedicalTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  treatmentSpotId: string
): Promise<MedicalTreatmentSpotBeforeCheckResponse> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${treatmentSpotId}/`,
    method: 'get'
  })
    .then(response => {
      if (!response.data.data.attributes.result) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠が確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return response.data.data.attributes
    })
    .catch(() => {
      AlertModule.SetErrorAlert({
        errorTime: new Date(),
        message: 'エラーが発生しました'
      })
      return false
    })

export const beforeCheckMedicalDoctorTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  treatmentSpotId: string,
  memberId: string
): Promise<MedicalTreatmentSpotBeforeCheckResponse> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${treatmentSpotId}/${memberId}/`,
    method: 'get'
  })
    .then(response => {
      if (!response.data.data.attributes.result) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return response.data.data.attributes
    })
    .catch(() => {
      AlertModule.SetErrorAlert({
        errorTime: new Date(),
        message: 'エラーが発生しました'
      })
      return false
    })
