









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ReservationCaution } from '@/interface/api'
@Component({
  name: 'ReservationCautionTemplate'
})
export default class ReservationCautionTemplate extends Vue {
  @Prop({ default: () => [] }) private traetmentNotices!: ReservationCaution[]
  @Prop({ default: false }) private loading!: boolean
  private checkbox = false
}
