import dayjs from '@/plugins/dayjs'
import { DateFormat } from '@/utils/constants'

export const formatUuid = (id: string) :string => {
  return id.slice(0, 8) + '-' + id.slice(8, 12) + '-' + id.slice(12, 16) + '-' + id.slice(16, 20) + '-' + id.slice(20)
}

/**
 * 日付をフォーマットする。
 *
 * @param date 日付オブジェクト、または日付文字列、または日付ミリ秒
 * @param dateFormat 日付フォーマット(未指定の場合は YYYY/MM/DD とする)
 * @return フォーマット後の日付文字列(不正な日付を指定した場合は空文字を返却する)
 */
export const formatDate = (date: Date | string | number = '', dateFormat: DateFormat = DateFormat.YYYY_MM_DD): string => {
  const day = dayjs(date)
  if (!day.isValid()) return ''
  return day.format(dateFormat)
}
