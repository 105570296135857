




































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Address, ClinicResponse } from '@/interface/api'
import { DateFormat, TreatmentCategoryOption } from '@/utils/constants'
import { formatDate } from '@/utils/index'

@Component({
  name: 'ConfirmTemplate'
})
export default class ConfirmTemplate extends Vue {
  @Prop({ default: '' }) private medicalBusinessId!: string
  @Prop({ default: () => null }) private selectedClinic!: ClinicResponse
  @Prop({ default: '' }) private selectedClinicalDepartmentId!: string
  @Prop({ default: '' })
  private selectedClinicDeaprtmentTreatmentCourseId!: string

  @Prop({ default: '' }) private isFirstTreatment!: number
  @Prop({ default: '' }) private selectedTreatmentCategoryName!: string
  @Prop({ default: '' }) private selectedDoctorName!: string
  @Prop({ default: new Date() }) private spotStartDatetime!: Date
  @Prop({ default: new Date() }) private spotEndDatetime!: Date
  @Prop({ default: false })
  private canFirstReservationForEachDoctorFlug!: boolean

  @Prop({ default: false })
  private canSecondLaterReservationForEachDoctorFlug!: boolean

  @Prop({ default: false }) private loading!: boolean

  private jointAddress(address: Address): string {
    return (
      address.prefecture +
      address.city +
      address.other1 +
      (address.other2 ? ' ' + address.other2 : '') +
      (address.other3 ? ' ' + address.other3 : '') +
      (address.other4 ? ' ' + address.other4 : '') +
      (address.other5 ? ' ' + address.other5 : '') +
      (address.other6 ? ' ' + address.other6 : '')
    )
  }

  private formatDate(date: Date): string {
    return formatDate(date, DateFormat.YYYYNEN_MMGATU_DDNICHI)
  }

  private formatDateTime(date: Date): string {
    return formatDate(date, DateFormat.HH_mm)
  }

  private get clinicalDepartmentName(): string {
    const target = this.selectedClinic!.clinical_departments.find(
      department => department.id === this.selectedClinicalDepartmentId
    )
    if (!target) {
      return ''
    }

    const targetCourse = (target.treatment_courses || []).find(
      course => course.id === this.selectedClinicDeaprtmentTreatmentCourseId
    )
    return targetCourse ? `${target.name} - ${targetCourse.name}` : target.name
  }

  private get treatmentCategoryName(): string {
    const target = TreatmentCategoryOption.find(
      category => category.name === this.selectedTreatmentCategoryName
    )
    return target ? target.label : ''
  }
}
